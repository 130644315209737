import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DifficultLevel, Quiz, quizSubmitResult, userQuizData } from '../models/quiz.model';
import { environment } from 'src/environments/environment';
import { Question } from '../models/question.model';
import { Category } from '../models/category';

const BASE_URL = environment.API_URL;
@Injectable({
  providedIn: 'root'
})
export class QuizLandService {
  constructor(private http: HttpClient) {}

  getQuizListByPage(page: number, items: number = 10): Observable<Quiz[]> {
    return <Observable<Quiz[]>>(
      this.http.get(`${BASE_URL}quizzes/quizlist?page=${page}&limit=${items}&sort=createAt,desc&search=`)
    );
  }

  getQuizTankListByPage(
    page: number,
    searchvalue: string,
    filterAuthor: string,
    filterCategory: string,
    filterDifficulty: string,
    sortBy: string,
    sortDirection: string,
    items: number = 10
  ): Observable<Quiz[]> {
    return <Observable<Quiz[]>>(
      this.http.get(
        `${BASE_URL}quizzes/quiztank?page=${page}&limit=${items}&author=${filterAuthor}&category=${filterCategory}&difficultlevel=${filterDifficulty}&sort=${sortBy},${sortDirection}&search=${searchvalue}`
      )
    );
  }

  getQuizListBySearch(searchValue: string): Observable<Quiz[]> {
    return <Observable<Quiz[]>>this.http.get(`${BASE_URL}quizzes/quizlist?search=${searchValue}`);
  }

  getQuizTankListBySearch(searchValue: string): Observable<Quiz[]> {
    return <Observable<Quiz[]>>this.http.get(`${BASE_URL}quizzes/quiztank?search=${searchValue}`);
  }

  getMyQuizList(): Observable<any[]> {
    return <Observable<any[]>>this.http.get(`${BASE_URL}quizzes/myquiz`);
  }

  createQuiz(data: any): Observable<Quiz> {
    return <Observable<Quiz>>this.http.post(`${BASE_URL}quizzes`, data);
  }

  getQuiz(quizId: string): Observable<Quiz> {
    return <Observable<Quiz>>this.http.get(`${BASE_URL}quizzes/${quizId}`);
  }

  getQuizListByUserId(userId: number): Observable<Quiz[]> {
    return <Observable<Quiz[]>>this.http.get(`${BASE_URL}quizzes/quizzesbyuser/${userId}`);
  }

  getIsUserQuiz(quizId: string, userId: string | null): Observable<any> {
    if (userId) return <Observable<userQuizData>>this.http.get(`${BASE_URL}quizzes/userquizparams/${quizId}/${userId}`);
    return <Observable<userQuizData>>this.http.get(`${BASE_URL}quizzes/userquiz/${quizId}`);
  }

  addUserToQuiz(quizId: string): Observable<any> {
    return <Observable<any>>this.http.post(`${BASE_URL}quizUser`, { quizId });
  }

  updateQuiz(quiz: any): Observable<Quiz> {
    return <Observable<Quiz>>this.http.put(`${BASE_URL}quizzes/${quiz.id}`, quiz);
  }

  deleteQuiz(quizId: string): Observable<any> {
    return <Observable<any>>this.http.delete(`${BASE_URL}quizzes/${quizId}`);
  }

  duplicateQuiz(quizId: string): Observable<Quiz> {
    return <Observable<Quiz>>this.http.post(`${BASE_URL}quizzes/duplicatedquiz`, { quizId });
  }

  uploadImage(data: FormData) {
    return <Observable<any>>this.http.post(`${BASE_URL}images/`, data);
  }

  uploadImageCompression(data: FormData) {
    return <Observable<any>>this.http.post(`${BASE_URL}images/imagescompression`, data);
  }

  uploadFile(data: FormData | null) {
    return this.http.post<any>(`${BASE_URL}files`, data);
  }

  getQuestionsByQuizId(quizId: string): Observable<Quiz> {
    return <Observable<Quiz>>this.http.get(`${BASE_URL}quizzes/${quizId}`);
  }

  addQuizQuestions(data: Question): Observable<Question> {
    return <Observable<Question>>this.http.post(`${BASE_URL}questions`, data);
  }

  updateQuizeQuestion(question: Question): Observable<Question> {
    return <Observable<Question>>this.http.put(`${BASE_URL}questions/${question._id}`, question);
  }

  deleteQuizQuestion(questionId: string): Observable<any> {
    return <Observable<any>>this.http.delete(`${BASE_URL}questions/${questionId}`);
  }

  getCategories(): Observable<Category[]> {
    return <Observable<Category[]>>this.http.get(`${BASE_URL}categories`);
  }

  getDifficulties(): Observable<DifficultLevel[]> {
    return <Observable<DifficultLevel[]>>this.http.get(`${BASE_URL}difficultlevels`);
  }

  submitQuiz(data: quizSubmitResult): Observable<any> {
    return <Observable<any>>this.http.post(`${BASE_URL}quizResults`, data);
  }

  getQuizStatisticsByQuizId(quizId: string): Observable<any> {
    return <Observable<any>>this.http.get(`${BASE_URL}quizzes/quizstatistics/${quizId}`);
  }

  teacherCommentAndTotalScore(
    quizId: string,
    userId: string | null,
    data: { commentTeacher: string; totalScore: string }
  ): Observable<any> {
    return <Observable<any>>this.http.put(`${BASE_URL}quizResults/commentteacher/${quizId}/${userId}`, data);
  }

  teacherReview(quizId: string, userId: string | null, data: any): Observable<any> {
    return <Observable<any>>this.http.put(`${BASE_URL}quizResults/teacherreview/${quizId}/${userId}`, data);
  }

  sendFile(data: any): Observable<any> {
    return <Observable<any>>this.http.post(`${BASE_URL}files`, data);
  }

  sendQuizErrorReport(data: any): Observable<any> {
    return <Observable<any>>this.http.post(`${BASE_URL}quizErrors`, data);
  }

  getAuthorList(): Observable<string[]> {
    return this.http.get<string[]>(`${BASE_URL}quizzes/authorslist`);
  }

  getTeacherRate(userId: number): Observable<number> {
    return <Observable<number>>this.http.get(`${BASE_URL}/quizzes/teacherrate/${userId}`);
  }
}
